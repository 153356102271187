import playdateService from "../api/services/playdateService";
import { getCookie } from "@/utils/cookies";
import {
  APPROVED_AGE_COOKIE,
  EXISTING_POPUPS,
  POPUP_AGE_APPROVAL,
  POPUP_BUY_SUBSCRIPTION,
  POPUP_CHANGE_AVATAR,
  POPUP_COINSCHARGE,
  POPUP_COINSCHARGE_SUCCESS,
  POPUP_COOKIES,
  POPUP_DOUBLE_OPT_IN_FINISH,
  POPUP_FEATURE_FEEDBACK,
  POPUP_FEATURE_INTRODUCE,
  POPUP_FEEDBACK,
  POPUP_FEEDBACK_REMOVAL,
  POPUP_FEEDBACK_SUCCESS,
  POPUP_MODEL_TIP,
  POPUP_MODEL_TIP_OFFER,
  POPUP_MODEL_TIP_SUCCESS,
  POPUP_ORGANIC,
  POPUP_PLAYDATE_FEEDBACK,
  POPUP_PLAYDATE_FORBIDDANCE,
  POPUP_PLAYDATE_REQUEST_SUCCESS,
  POPUP_TRANSACTIONAL,
  POPUP_BLACK_FRIDAY_DISCOUNT,
  POPUP_VALENTINES_DISCOUNT,
  SUCCESS_BUY_COOKIE,
} from "@/utils/variables";
import { getTld } from "@/utils/urlHelper";
import getFlash from "@/utils/getFlash";
import messageService from "@/api/services/messageService";
import { getApprovedCookies, getPopup, removePopup, setPopup, updatePopup } from "@/utils/storage";
import popupConfig from "@/configs/popup-config";
import { canShowAgeApproval } from "@/utils/popupHelper";
import router from "@/router/router";

export default () => {
  return {
    namespaced: true,
    state: {
      popups: {},
    },
    getters: {
      canShow:
        (state, getters, rootState, rootGetters) =>
        (id, byDefault = false) => {
          if (popupConfig[id].requiresAuth && !rootGetters["user/isLoggedIn"]) {
            return false;
          }

          if (state.popups[id] !== undefined) {
            return state.popups[id].show ?? byDefault;
          }

          const popup = getPopup(id);
          return popup?.show ?? byDefault;
        },
      getPopupData: (state) => (id) => {
        if (state.popups[id] !== undefined) {
          return state.popups[id].data ?? null;
        }

        const popup = getPopup(id);
        return popup?.data ?? false;
      },
      canShowSubscriptionPopup(state, getters, rootState, rootGetters) {
        const success = getCookie("success_buy") || false;

        if (success) {
          return false;
        }

        if (rootGetters["user/isPremium"]) {
          return false;
        }

        // todo: revisit

        const canShow = getters.canShow(POPUP_BUY_SUBSCRIPTION);

        const isPaywall =
          rootGetters["user/hasSignupToSaleFeature"] ||
          rootGetters["user/hasMrSkinDiscountFeature"] ||
          rootGetters["user/hasLadiesDiscountFeature"] ||
          rootGetters["user/hasCustomerSupportDiscountFeature"] ||
          rootGetters["user/hasBlackFridayDiscountFeature"] ||
          rootGetters["user/hasValentinesDayDiscountFeature"] ||
          rootGetters["user/hasWDSDiscountFeature"];

        if (isPaywall && canShow) {
          router.push("/paywall");
          return false;
        }

        return canShow;
      },
      canShowFeedbackPopup(_, getters) {
        return getters.canShow(POPUP_FEEDBACK);
      },
      canShowFeedbackSuccessPopup(_, getters) {
        return getters.canShow(POPUP_FEEDBACK_SUCCESS);
      },
      canShowFeedbackRemovalPopup(_, getters) {
        return getters.canShow(POPUP_FEEDBACK_REMOVAL);
      },
      canShowLawRestrictionPopup(state, getters, rootState, rootGetters) {
        if (rootGetters["user/isLoggedIn"]) {
          return false;
        }

        const restrictedRegions = ["texas", "alabama", "florida", "indiana", "kansas", "louisiana", "tennessee"];

        return restrictedRegions.indexOf(rootGetters["visit/getVisit"]?.region?.toLowerCase()) > -1;
      },
      canShowCoinsChargePopup(_, getters) {
        const success = getCookie("success_coinscharge") || false;

        if (success) {
          return false;
        }

        return getters.canShow(POPUP_COINSCHARGE);
      },
      isCoinsChargePopupBlank(_, getters) {
        return getters.getPopupData(POPUP_COINSCHARGE)?.blank ?? false;
      },
      canShowPlaydateFeedbackPopup(_, getters) {
        return getters.canShow(POPUP_PLAYDATE_FEEDBACK);
      },
      getPlaydateFeedbackPopup(_, getters) {
        return getters.getPopupData(POPUP_PLAYDATE_FEEDBACK) ?? null;
      },
      canShowCookiesPopup(_, getters, rootState, rootGetters) {
        if (!rootGetters["visit/isEUCountry"] || getTld() === "io") {
          return false;
        }

        const show = getters.canShow(POPUP_COOKIES, true);
        return !show ? show : !getApprovedCookies() ?? show;
      },
      canShowAgeApprovalPopup(_, getters) {
        if (!canShowAgeApproval()) {
          return;
        }

        const show = getters.canShow(POPUP_AGE_APPROVAL, true);
        return !show ? show : !getCookie(APPROVED_AGE_COOKIE) ?? show;
      },
      canShowSuccessCoinschargePopup(_, getters) {
        const show = getters.canShow(POPUP_COINSCHARGE_SUCCESS, null);
        return show === false ? show : getFlash("success_coinscharge") ?? show;
      },
      canShowPlaydateForbiddancePopup(_, getters) {
        return getters.canShow(POPUP_PLAYDATE_FORBIDDANCE);
      },
      getPlaydateForbiddancePopupData(_, getters) {
        return getters.getPopupData(POPUP_PLAYDATE_FORBIDDANCE)?.data ?? null;
      },
      getPlaydateForbiddancePopupAskCamera(_, getters) {
        return getters.getPopupData(POPUP_PLAYDATE_FORBIDDANCE)?.askCamera ?? null;
      },
      canShowPlaydateRequestSuccessPopup(_, getters) {
        const show = getters.canShow(POPUP_PLAYDATE_REQUEST_SUCCESS, null);
        return show === false ? show : getFlash("success_playdate_request") ?? show;
      },
      canShowTransactionalPopup(_, getters) {
        setPopup(POPUP_TRANSACTIONAL, false);
        return getters.canShow(POPUP_TRANSACTIONAL);
      },
      getTransactionalPopupMessage(_, getters) {
        return getters.getPopupData(POPUP_TRANSACTIONAL) ?? null;
      },
      canShowFreechatPopup(state, getters, rootState, rootGetters) {
        const profile = rootGetters["user/getProfile"];
        return profile?.features?.freechat?.is_active || false;
      },
      canShowDoubleOptInPopup(state, getters, rootState, rootGetters) {
        if (rootGetters["user/isLoggedIn"]) {
          if (getCookie(SUCCESS_BUY_COOKIE)) {
            return false;
          }

          const profile = rootGetters["user/getProfile"];
          return profile?.features?.double_opt_in?.is_active || false;
        }

        return false;
      },
      canShowDoubleOptInNewPopup(state, getters, rootState, rootGetters) {
        const profile = rootGetters["user/getProfile"];
        const feature = profile?.features?.signup_to_sale;
        return getters.canShowDoubleOptInPopup && feature && feature?.is_active && feature?.version === "experiment_european";
      },
      canShowDoubleOptInFinishPopup(_, getters) {
        return getters.canShow(POPUP_DOUBLE_OPT_IN_FINISH);
      },
      canShowOrganicPopup(state, getters, rootState, rootGetters) {
        const show = getters.canShow(POPUP_ORGANIC, null);

        if (show === false) {
          return false;
        }

        const profile = rootGetters["user/getProfile"];

        return profile?.features?.organic_popup?.is_active || false;
      },
      canShowChangeAvatarPopup(_, getters) {
        return getters.canShow(POPUP_CHANGE_AVATAR);
      },
      canShowFeatureFeedbackPopup(_, getters, rootState, rootGetters) {
        const show = getters.canShow(POPUP_FEATURE_FEEDBACK, null);

        if (show === false) {
          return false;
        }

        return rootGetters["feature/showFeedbackPopup"];
      },
      canShowFeatureIntroducePopup(_, getters, rootState, rootGetters) {
        const show = getters.canShow(POPUP_FEATURE_INTRODUCE, null);

        if (show === false) {
          return false;
        }

        return rootGetters["feature/showIntroducePopup"];
      },
      canShowModelTipPopup(_, getters) {
        return getters.canShow(POPUP_MODEL_TIP);
      },
      canShowModelTipOfferPopup(_, getters) {
        return getters.canShow(POPUP_MODEL_TIP_OFFER);
      },
      canShowModelTipSuccessPopup(_, getters) {
        const show = getters.canShow(POPUP_MODEL_TIP_SUCCESS, null);
        return show === false ? show : getFlash("success_model_tip") ?? show;
      },
      canShowBlackFridayDiscountPopup(state, getters, rootState, rootGetters) {
        const show = getters.canShow(POPUP_BLACK_FRIDAY_DISCOUNT, null);

        if (show === false) {
          return false;
        }

        const profile = rootGetters["user/getProfile"];

        const feature = profile?.features?.black_friday_discount_24;
        return (feature?.is_active && !feature?.version?.includes("subscribers")) || false;
      },
      canShowValentinesDiscountPopup(state, getters, rootState, rootGetters) {
        const show = getters.canShow(POPUP_VALENTINES_DISCOUNT, null);

        if (show === false) {
          return false;
        }

        const profile = rootGetters["user/getProfile"];

        const feature = profile?.features?.valentines_day_discount_25;
        return (feature?.is_active && feature?.version !== "subscribers") || false;
      },
    },
    mutations: {
      SET_SHOW(state, { id, show, data }) {
        const popups = {};

        popups[id] = {
          show: show,
          data: data,
        };

        state.popups = {
          ...state.popups,
          ...popups,
        };
      },
      SET_DATA(state, { id, data }) {
        const popups = {};
        const popupData = state.popups[id]?.data || {};
        const newData = { ...popupData, ...data };

        popups[id] = {
          show: true,
          data: newData,
        };

        state.popups = {
          ...state.popups,
          ...popups,
        };
      },
    },
    actions: {
      show({ commit }, payload = {}) {
        const { id = null, data = null } = payload;

        if (id === null) {
          return;
        }

        commit("SET_SHOW", { id, show: true, data });
        setPopup(id, true, data);
      },
      hide({ commit }, payload = {}) {
        const { id = null, data = null, remove = false } = payload;

        if (id === null) {
          return;
        }

        commit("SET_SHOW", { id, show: false, data });

        if (remove) {
          removePopup(id);
        } else {
          setPopup(id, false, data);
        }
      },
      update({ commit }, payload = {}) {
        const { id = null, data = null } = payload;

        if (id === null) {
          return;
        }

        commit("SET_DATA", { id, data });
        updatePopup(id, data);
      },
      closeAll({ dispatch }) {
        EXISTING_POPUPS.forEach((id) => {
          dispatch("hide", { id, remove: true });
        });
      },

      async checkPlaydateFeedbackPopup({ dispatch }) {
        dispatch("hide", { id: POPUP_PLAYDATE_FEEDBACK, data: null });

        const response = await playdateService.getFeedback();

        if (response !== null) {
          dispatch("show", { id: POPUP_PLAYDATE_FEEDBACK, data: response });
        }
      },

      async checkTransactionalMessagePopup({ dispatch }) {
        const popupMessage = await messageService.getTransactionalMessagePopup();

        if (popupMessage !== null) {
          dispatch("show", { id: POPUP_TRANSACTIONAL, data: popupMessage });
        }
      },
    },
  };
};
