import { ApiError } from "@/api/apiError";
import { getVisitUid, loadAccessToken, setVisitUid } from "@/utils/storage";
import { getApiHost, getTld } from "@/utils/urlHelper";

const RETRY_LIMIT = 2;
const API_ROOT = getApiHost();

const stripObject = (obj) =>
  Object.keys(obj)
    .filter((k) => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});

const createHeaders = (token, isFormData) => {
  const headers = {
    Accept: "application/json",
    "X-Source-Tld": getTld(),
    "X-Visit-Uid": getVisitUid(),
  };

  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

const handleResponse = async (response) => {
  const visitUid = response.headers.get("X-Visit-Uid");

  if (visitUid) {
    setVisitUid(visitUid);
  }

  if (response.status === 204) {
    return {};
  }

  const contentType = response.headers.get("content-type");

  if (!contentType || !contentType.includes("application/json")) {
    const text = await response.text();
    throw new ApiError(text, { status: response.status });
  }

  const body = await response.json();

  if (response.status >= 400) {
    throw new ApiError(response.message, {
      status: response.status,
      ...body,
    });
  }

  return body;
};

const makeRequest = async (method, path, params, options) => {
  const { signal, rawParams, count = 0 } = options;
  const url = new URL(API_ROOT + path);
  let requestBody = null;

  if (rawParams) {
    requestBody = params;
  } else if (typeof params === "object") {
    if (method === "GET") {
      url.search = new URLSearchParams(stripObject(params)).toString();
    } else {
      requestBody = JSON.stringify(params);
    }
  }

  if (count > RETRY_LIMIT) {
    const apiRes = await fetch("https://api.ersties.com");
    const stApiRes = await fetch("https://st-web-be.ersties.com");
    const oldRes = await fetch("https://ersties.com");

    throw new ApiError("Retry limit reached", {
      errorMessage: {
        code: "MaxRetries",
        message: "Retry limit reached",
        path,
        apiStatus: apiRes.status,
        stApiStatus: stApiRes.status,
        oldStatus: oldRes.status,
      },
    });
  }

  const token = loadAccessToken();
  const headers = createHeaders(token, requestBody instanceof FormData);
  let response = null;

  try {
    response = await fetch(url.toString(), {
      method,
      headers,
      body: requestBody,
      credentials: "include",
      mode: "cors",
      cache: "no-store",
      referrerPolicy: "unsafe-url",
      signal,
    });

    if (response.status >= 500) {
      return makeRequest(method, path, params, { ...options, count: count + 1 });
    }
  } catch (error) {
    if (error.name === "AbortError") {
      return null;
    }

    return makeRequest(method, path, params, { ...options, count: count + 1 });
  }

  return await handleResponse(response);
};

const api = {
  get: (path, params, signal) => makeRequest("GET", path, params, { signal }),
  post: (path, params, rawParams, signal) => makeRequest("POST", path, params, { rawParams, signal }),
  put: (path, params, rawParams, signal) => makeRequest("PUT", path, params, { rawParams, signal }),
  patch: (path, params, rawParams, signal) => makeRequest("PATCH", path, params, { rawParams, signal }),
  delete: (path, params, rawParams, signal) => makeRequest("DELETE", path, params, { rawParams, signal }),
};

export default api;
