import request from "@/api/request";

class GalleryService {
  static async getList({ page, order }) {
    return request.get("/galleries", { page, order });
  }

  static async getGallery({ id }) {
    return request.get(`/galleries/${id}`);
  }

  static async getTopShootings() {
    return request.get(`/galleries/top_shootings`);
  }

  static async getGalleryChapters({ id }) {
    return request.get(`/galleries/${id}/chapters`);
  }

  static async getGalleryChapter({ galleryId, galleryChapterId }) {
    return request.get(`/galleries/${galleryId}/${galleryChapterId}`);
  }

  static async getRelatedGalleries({ id }) {
    return request.get(`/galleries/${id}/related`);
  }
}

export default GalleryService;
