<template>
  <div class="comment">
    <rating v-if="comment.user_rating" :value="comment.user_rating" :is-static="true" size="small" theme="dark" />
    <div class="date">
      {{ formatDate(comment.date) }} {{ $t("by") }}
      <span :class="{ 'team-member': isTeamMember(comment) }">
        <router-link v-if="isModel(comment)" :to="`/profile/${comment.model.id}`">
          {{ getName(comment) }}
        </router-link>
        <template v-else>
          {{ getName(comment) }}
        </template>
        <template v-if="isTeamMember(comment)">
          {{ $t(" from Ersties") }}
        </template>
      </span>

      <span v-if="!comment.is_verified" class="verification-note">
        {{ $t("(Your comment has been successfully submitted and will go live after moderator approval)") }}
      </span>
    </div>

    <div class="text">{{ comment.text }}</div>
    <div class="comment-actions">
      <like
        :id="comment.id"
        :count="comment.like_count"
        type="comment"
        theme="dark"
        icon="heart"
        :show-tooltip="false"
        @on-like-update="onLike(comment)"
      />
      <span class="reply-icon" @click="onReplyClick">{{ comment?.childs?.length }}</span>
      <comment-delete v-if="comment.is_mine && !comment?.childs?.length" :comment-id="comment.id" />
      <button v-if="!showReplyForm" class="btn btn-sm" @click="onReplyClick">{{ $t("Reply") }}</button>
    </div>

    <div class="replies">
      <comment-form v-if="showReplyForm" :video-id="videoId" :parent-id="comment.id" @on-close="onReplyClose" />

      <template v-if="comment?.childs?.length">
        <div v-for="(reply, rk) in comment.childs" :key="rk" class="reply">
          <div class="date">
            {{ formatDate(reply.date) }} {{ $t("by") }}
            <span :class="{ 'team-member': isTeamMember(reply) }">
              <router-link v-if="isModel(reply)" :to="`/profile/${reply.model.id}`">
                {{ getName(reply) }}
              </router-link>
              <template v-else>
                {{ getName(reply) }}
              </template>
              <template v-if="isTeamMember(reply)">
                {{ $t(" from Ersties") }}
              </template>
            </span>

            <span v-if="!reply.is_verified" class="verification-note">
              {{ $t("(Your comment has been successfully submitted and will go live after moderator approval)") }}
            </span>
          </div>
          <div class="text">{{ reply.text }}</div>
          <div class="comment-actions">
            <like
              :id="reply.id"
              :count="reply.like_count"
              type="comment"
              theme="dark"
              icon="heart"
              :show-tooltip="false"
              @on-like-update="onLike(reply, false)"
            />
            <comment-delete v-if="reply.is_mine" :comment-id="reply.id" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CommentForm from "@/components/comment-form/index.vue";
import TimeAgo from "javascript-time-ago";
import de from "javascript-time-ago/locale/de";
import en from "javascript-time-ago/locale/en";
import Rating from "@/components/rating/index.vue";
import Like from "@/components/like/index.vue";
import { amplitudeTrack } from "@/utils/amplitude";
import CommentDelete from "@/components/comment-delete/index.vue";
import { getModelName } from "@/utils/modelHelper";

export default {
  name: "comment",
  components: { CommentDelete, Like, Rating, CommentForm },
  props: {
    videoId: {
      type: [String, Number],
      required: true,
    },
    comment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showReplyForm: false,
    };
  },

  computed: {
    lang() {
      return this.$root.$i18n.locale;
    },
  },

  methods: {
    formatDate(ts) {
      TimeAgo.addLocale(en);
      TimeAgo.addLocale(de);

      let locale = null;

      switch (this.lang) {
        case "de":
          locale = "de-DE";
          break;
        case "fr":
          locale = "fr-FR";
          break;
        case "en":
        default:
          locale = "en-EN";
          break;
      }

      const timeAgo = new TimeAgo(locale);

      return timeAgo.format(new Date(ts * 1000));
    },
    onReplyClick() {
      this.showReplyForm = true;
    },
    onReplyClose() {
      this.showReplyForm = false;
    },
    isModel(comment) {
      return comment.model !== null;
    },
    isTeamMember(comment) {
      return comment.user.is_team_member || this.isModel(comment);
    },
    getName(comment) {
      if (this.isModel(comment)) {
        return getModelName(comment.model);
      }

      return comment.user.name;
    },
    onLike(comment, isMain = true) {
      const props = {
        "Comment Type": isMain ? "Main comment" : "sub-comment",
        "Video ID": this.videoId,
        "Comment author": comment.model ? "Model" : comment.user.is_team_member ? "Team member" : "User",
      };

      const rating = comment.user_rating;

      if (isMain && rating) {
        props.Rating = rating;
      }

      amplitudeTrack("Comment liked", props);
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
