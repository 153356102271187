<template>
  <div ref="theater" class="theater-player" :class="{ 'pip-mode': isPipEnabled }" @mousedown="resizePip">
    <span class="theater-close" :class="{ hidden: controlHidden }" @click="close">
      <i class="icon icon-cross" />
    </span>
    <template v-if="video && !hide">
      <memberarea-player
        :video="video"
        :full-height="isFullHeight"
        :autoplay="isAutoplay"
        @on-user-active="onUserActive"
        @on-user-inactive="onUserInactive"
        @click.stop="onPlayClick"
      />
    </template>

    <div v-if="video" class="info" :style="{ maxWidth: maxWidth + 'px' }">
      <h1 class="title">{{ customTitle }}</h1>
      <div class="details">
        <h1>
          <span class="label">{{ $t("From:") }}</span>
          <router-link :to="'/shoot/' + video.gallery.id">{{ video.gallery["title_" + lang] }}</router-link>
        </h1>
        <participated-models :models="models" tag="h1" />
        <model-tip-button v-if="canBeTipped" :models="tippingModels" :simple="true" location="video page" />
        <app-button :full-width="false" icon="comments" class="comment-button d-md-none" :title="$t('Comments')" @on-click="onCommentsClick" />
      </div>

      <div class="actions">
        <rating
          :value="video.rating"
          type="video"
          :entity-id="video.id"
          :default-label="$t('Rate it')"
          theme="dark"
          :recommendation-item-id="video.recommendation_item_id"
          :recommendation-source-id="video.recommendation_source_id"
        />
        <favorite
          :entity-id="video.id"
          type="video"
          theme="dark"
          :recommendation-item-id="video.recommendation_item_id"
          :recommendation-source-id="video.recommendation_source_id"
        />
        <video-download :video="video" :long="true" theme="dark" />
      </div>

      <div class="theater-video-footer">
        <div class="tags">
          <tag-button v-for="tag in video.tags" :key="tag.id" :tag="tag" @select-tag="selectTag" />
        </div>

        <similar-videos v-if="video && !hide" :video-id="videoId" />
      </div>

      <comments v-if="video && !hide" ref="comments" :video-id="videoId" :total="video.total_comments" />
    </div>
  </div>
</template>

<script>
import VideoDownload from "@/components/video-download/index.vue";
import ModelTipButton from "@/components/model-tip-button/index.vue";
import Rating from "@/components/rating/index.vue";
import TagButton from "@/components/tag-button/index.vue";
import Favorite from "@/components/favorite/index.vue";
import { mapGetters } from "vuex";
import { updateVideoTags } from "@/utils/urlHelper";
import { POPUP_MODEL_TIP, POPUP_MODEL_TIP_OFFER, ROUTE_SCROLL_DELAY } from "@/utils/variables";
import { amplitudeTrack, amplitudeVideoTrack } from "@/utils/amplitude";
import featureService from "@/api/services/featureService";
import SimilarVideos from "@/components/similar-videos/index.vue";
import Comments from "@/components/comments/index.vue";
import AppButton from "@/components/app-button/index.vue";
import browserHelper from "@/utils/browserHelper";
import ParticipatedModels from "@/components/participated-models/index.vue";
import MemberareaPlayer from "@/components/player/PaidPlayer.vue";
import { addDetailedView } from "@/utils/recommendor";

export default {
  name: "theater-player",
  components: {
    MemberareaPlayer,
    ParticipatedModels,
    AppButton,
    Comments,
    SimilarVideos,
    Favorite,
    TagButton,
    Rating,
    ModelTipButton,
    VideoDownload,
  },
  data() {
    return {
      controlHidden: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      videoRatio: 16 / 9,
      hide: false,
      pipWidth: browserHelper.isMobileDevice ? 300 : 450,
      pipAnimation: false,
    };
  },
  computed: {
    ...mapGetters("player", {
      isPipEnabled: "isPipModeEnabled",
      isAutoplay: "isAutoplay",
    }),
    ...mapGetters("video", {
      videoId: "getActiveVideo",
      isPlayable: "isPlayable",
      getVideo: "getVideo",
      lastPortion: "getLastPortion",
    }),
    ...mapGetters("user", {
      profile: "getProfile",
    }),
    lang() {
      return this.$root.$i18n.locale;
    },
    video() {
      return this.getVideo(this.videoId);
    },
    isFullHeight() {
      if (this.isPipEnabled || this.pipAnimation) {
        return false;
      }

      return this.windowWidth / this.windowHeight >= this.videoRatio;
    },
    maxWidth() {
      return this.windowHeight * this.videoRatio;
    },
    customTitle() {
      return this.video ? this.video.gallery["title_" + this.lang] + " - " + this.video["title_" + this.lang] : null;
    },
    models() {
      return this.video ? this.video.participated_models : [];
    },
    tippingModels() {
      const models = this.models.length ? this.models : [];
      return [...models.filter((model) => model.tip_available)];
    },
    canBeTipped() {
      if (!this.profile?.features?.model_tip?.is_active) {
        return false;
      }

      return this.tippingModels.length;
    },
  },

  watch: {
    isPipEnabled(value) {
      const theater = this.$refs.theater;

      this.pipAnimation = true;

      if (value) {
        theater.scroll({
          top: 0,
          behavior: "smooth",
        });

        document.body.classList.remove("theater-active");
        theater.style.width = this.pipWidth + "px";
        theater.style.height = this.pipWidth / this.videoRatio + "px";
      } else {
        document.body.classList.add("theater-active");
        theater.style.width = null;
        theater.style.height = null;
      }

      setTimeout(() => {
        this.pipAnimation = false;
      }, 500);
    },
    async video(video) {
      this.controlHidden = false;

      if (!video) {
        await this.loadVideo();
      }

      if (this.$route.hash.indexOf(this.videoId) === -1) {
        this.$router.replace({
          ...this.$route,
          ...{ hash: "#play-" + this.videoId },
        });
      }

      this.$refs.theater.scroll({
        top: 0,
        behavior: "smooth",
      });

      this.hide = true;
      this.$nextTick(() => {
        this.hide = false;
      });
    },
    $route(val) {
      if (val.hash.indexOf("comments") !== -1) {
        setTimeout(() => {
          this.scrollToComments();
        }, 600);
      }
    },
  },

  methods: {
    async loadVideo() {
      try {
        await this.$store.dispatch("video/getVideo", this.videoId);
      } catch (e) {
        this.close();
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    close() {
      this.$store.dispatch("player/setTheaterMode", { videoId: null });

      let location = window.location.pathname;

      if (window.location.search) {
        location += window.location.search;
      }

      history.pushState("", document.title, location);
    },
    async selectTag(tag) {
      await updateVideoTags(tag.id, tag[`name_${this.lang}`]);
    },
    onUserActive() {
      this.controlHidden = false;
    },
    onUserInactive() {
      this.controlHidden = true;
    },

    resizePip(e) {
      if (!this.isPipEnabled) {
        return;
      }

      const isLeftBorder = e.offsetX <= 4;
      const isTopBorder = e.offsetY <= 4;

      if (!isLeftBorder && !isTopBorder) {
        return;
      }

      const theater = this.$refs.theater;
      theater.classList.add("on-resize");

      let dragX = e.clientX;
      let dragY = e.clientY;

      document.onmousemove = (e) => {
        if (isLeftBorder) {
          const theaterWidth = parseInt(getComputedStyle(theater, "").width);

          this.pipWidth = Math.min(theaterWidth + (dragX - e.clientX), this.windowWidth * 0.85);

          theater.style.width = this.pipWidth + "px";
          theater.style.height = theaterWidth / this.videoRatio + "px";
          dragX = e.clientX;
        }

        if (isTopBorder) {
          const theaterHeight = parseInt(getComputedStyle(theater, "").height);

          this.pipWidth = theaterHeight * this.videoRatio;
          const height = Math.min(theaterHeight + (dragY - e.clientY), this.windowHeight * 0.95);

          theater.style.height = height + "px";
          theater.style.width = this.pipWidth + "px";
          dragY = e.clientY;
        }
      };

      document.onmouseup = () => {
        document.onmousemove = document.onmouseup = null;
        theater.classList.remove("on-resize");
      };
    },

    checkModelTipping() {
      if (this.lastPortion < 0.3) {
        return;
      }

      if (!this.canBeTipped) {
        return;
      }

      featureService
        .getModelTipOfferPopup()
        .then(() => {
          this.$store.dispatch("popup/show", {
            id: POPUP_MODEL_TIP_OFFER,
            data: {
              models: this.tippingModels.map((model) => model.id),
            },
          });
        })
        .catch(() => {});
    },
    onPlayClick() {
      if (!this.isPlayable(this.video)) {
        this.$store.dispatch("user/showSubscriptionPaywall");
      }
    },
    scrollToComments() {
      this.$refs.theater.scroll({
        top: this.$refs.comments.$el.offsetTop,
        behavior: "smooth",
      });
    },
    onCommentsClick() {
      amplitudeTrack("Comment clicked");
      this.scrollToComments();
    },
  },

  async created() {
    if (!this.video) {
      await this.loadVideo();
    } else {
      addDetailedView(this.video.recommendation_item_id, this.video.recommendation_source_id);
    }

    if (this.$route.hash.indexOf("comments") !== -1) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.scrollToComments();
        }, ROUTE_SCROLL_DELAY + 500);
      });
    }
  },

  mounted() {
    if (!this.isPipEnabled) {
      this.$nextTick(() => {
        document.body.classList.add("theater-active");
      });
    }

    if (!this.$route.hash) {
      this.$router.push({
        ...this.$route,
        ...{ hash: "#play-" + this.videoId },
      });
    }

    window.addEventListener("resize", this.onResize);
    amplitudeVideoTrack("video page open", this.videoId);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
    this.$store.dispatch("popup/hide", { id: POPUP_MODEL_TIP, remove: true });
  },
  unmounted() {
    document.body.classList.remove("theater-active");
    this.$store.dispatch("player/setPipMode", false);
    this.checkModelTipping();
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
