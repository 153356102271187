const {
  POPUP_DOUBLE_OPT_IN,
  POPUP_DOUBLE_OPT_IN_FINISH,
  POPUP_BUY_SUBSCRIPTION,
  POPUP_FEEDBACK,
  POPUP_FEEDBACK_SUCCESS,
  POPUP_FEEDBACK_REMOVAL,
  POPUP_COINSCHARGE,
  POPUP_COINSCHARGE_SUCCESS,
  POPUP_PLAYDATE_FEEDBACK,
  POPUP_COOKIES,
  POPUP_AGE_APPROVAL,
  POPUP_PLAYDATE_FORBIDDANCE,
  POPUP_PLAYDATE_REQUEST_SUCCESS,
  POPUP_TRANSACTIONAL,
  POPUP_REDDIT_DISCOUNT,
  POPUP_FREECHAT,
  POPUP_ORGANIC,
  POPUP_CHANGE_AVATAR,
  POPUP_FEATURE_FEEDBACK,
  POPUP_MODEL_TIP,
  POPUP_MODEL_TIP_SUCCESS,
  POPUP_MODEL_TIP_OFFER,
  POPUP_FEATURE_INTRODUCE,
  POPUP_BLACK_FRIDAY_DISCOUNT,
  POPUP_VALENTINES_DISCOUNT,
} = require("@/utils/variables");

const exports = {};
exports[POPUP_DOUBLE_OPT_IN] = { requiresAuth: true, initOnHash: false };
exports[POPUP_DOUBLE_OPT_IN_FINISH] = { requiresAuth: true, initOnHash: false };
exports[POPUP_BUY_SUBSCRIPTION] = { requiresAuth: true, initOnHash: true };
exports[POPUP_FEEDBACK] = { requiresAuth: true, initOnHash: false };
exports[POPUP_FEEDBACK_SUCCESS] = { requiresAuth: true, initOnHash: false };
exports[POPUP_FEEDBACK_REMOVAL] = { requiresAuth: true, initOnHash: false };
exports[POPUP_COINSCHARGE] = { requiresAuth: true, initOnHash: true };
exports[POPUP_COINSCHARGE_SUCCESS] = { requiresAuth: true, initOnHash: false };
exports[POPUP_PLAYDATE_FEEDBACK] = { requiresAuth: true, initOnHash: false };
exports[POPUP_PLAYDATE_FORBIDDANCE] = { requiresAuth: true, initOnHash: false };
exports[POPUP_PLAYDATE_REQUEST_SUCCESS] = { requiresAuth: true, initOnHash: false };
exports[POPUP_TRANSACTIONAL] = { requiresAuth: true, initOnHash: false };
exports[POPUP_REDDIT_DISCOUNT] = { requiresAuth: true, initOnHash: false };
exports[POPUP_FREECHAT] = { requiresAuth: true, initOnHash: false };
exports[POPUP_COOKIES] = { requiresAuth: false, initOnHash: false };
exports[POPUP_AGE_APPROVAL] = { requiresAuth: false, initOnHash: false };
exports[POPUP_ORGANIC] = { requiresAuth: true, initOnHash: false };
exports[POPUP_CHANGE_AVATAR] = { requiresAuth: true, initOnHash: false };
exports[POPUP_FEATURE_FEEDBACK] = { requiresAuth: true, initOnHash: false };
exports[POPUP_MODEL_TIP] = { requiresAuth: true, initOnHash: false };
exports[POPUP_MODEL_TIP_SUCCESS] = { requiresAuth: true, initOnHash: false };
exports[POPUP_MODEL_TIP_OFFER] = { requiresAuth: true, initOnHash: false };
exports[POPUP_FEATURE_INTRODUCE] = { requiresAuth: true, initOnHash: false };
exports[POPUP_BLACK_FRIDAY_DISCOUNT] = { requiresAuth: true, initOnHash: false };
exports[POPUP_VALENTINES_DISCOUNT] = { requiresAuth: true, initOnHash: false };

module.exports = exports;
