<template>
  <div class="carousel-model-item" @click="onClick">
    <div class="image-wrapper">
      <img v-lazy="thumb" class="favourite-model-item" />
    </div>
    <p class="title">{{ model["name_" + lang] }}</p>
  </div>
</template>

<script>
import { getModelThumb } from "@/utils/mediaHelper";
export default {
  name: "carousel-model-item",

  props: {
    model: {
      type: Object,
      require: true,
      default: null,
    },
  },

  emits: ["onClick"],

  data() {
    return {
      thumb: getModelThumb(this.model.thumbnail),
    };
  },

  computed: {
    lang() {
      return this.$root.$i18n.locale;
    },
  },

  methods: {
    onClick() {
      this.$router.push(
        "/profile/" + this.model.id + (this.model.recommendation_source_id ? "?recommendationId=" + this.model.recommendation_source_id : ""),
      );
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
