<template>
  <div class="comment-form" :class="{ 'reply-form': parentId }">
    <div
      v-if="profile.is_comment_banned"
      class="ban-notice"
      v-html="
        $t(
          `Your account has been restricted from commenting due to a violation of our <a href='/community_guidelines' target='_blank'>Community Guidelines</a>`,
        )
      "
    />

    <template v-else>
      <div v-if="parentId" class="close" @click="close"></div>
      <div class="form" @keyup.enter="onSubmit">
        <input v-model="text" type="text" :placeholder="placeholder" @focus="onFocus" />
        <app-button :title="$t('Submit')" :border-dark="false" :is-disabled="busy" @on-click="onSubmit" />
      </div>
      <div v-if="!parentId" class="note">
        {{ $t("Please follow our") }}
        <a href="/community_guidelines" target="_blank">{{ $t("Community Guidelines") }}</a>
      </div>
    </template>
  </div>
</template>

<script>
import AppButton from "@/components/app-button/index.vue";
import { amplitudeTrack } from "@/utils/amplitude";
import { mapGetters } from "vuex";

export default {
  name: "comment-form",
  components: { AppButton },
  props: {
    videoId: {
      type: [String, Number],
      required: true,
    },
    parentId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ["onClose"],
  data() {
    return {
      text: "",
      busy: false,
    };
  },
  computed: {
    ...mapGetters("user", { isPremium: "isPremium", profile: "getProfile" }),
    ...mapGetters("rating", ["getRating"]),
    amplitudeProps() {
      const props = {
        "Comment Type": this.parentId ? "sub-comment" : "Main comment",
        "Video ID": this.videoId,
        "Comment author": this.profile.model_id ? "Model" : this.profile.is_team_member ? "Team member" : "User",
      };

      const rating = this.getRating("video", this.videoId);

      if (!this.parentId && rating) {
        props.Rating = rating;
      }

      return props;
    },
    placeholder() {
      return !this.parentId ? this.$t("Leave a comment...") : this.$t("Reply to comment...");
    },
  },
  methods: {
    async onSubmit() {
      if (!this.text || this.busy) {
        return;
      }

      if (!this.isPremium) {
        this.$store.dispatch("user/showSubscriptionPaywall");
        return;
      }

      this.busy = true;

      await this.$store.dispatch("comments/postComment", {
        id: this.videoId,
        text: this.text,
        parent_id: this.parentId,
      });

      this.busy = false;

      amplitudeTrack("Comment submitted", this.amplitudeProps);

      this.text = "";
      this.close();
    },
    onFocus() {
      amplitudeTrack("Comment entry field clicked", this.amplitudeProps);
    },
    close() {
      this.$emit("onClose");
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
