<template>
  <component :is="tag" v-if="models.length > 0" class="participated-models">
    <span class="label">{{ $t("Models:") }}</span>

    <span v-for="(model, index) in models" :key="model.id">
      <template v-if="model.is_hidden && !hasWriterRole">{{ model["name_" + lang] }}</template>
      <router-link v-else :to="'/profile/' + model.id"> {{ model["name_" + lang] }}</router-link>
      <template v-if="index !== models.length - 1">, </template>
    </span>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import { ROLE_WRITER_TOKEN } from "@/utils/variables";

export default {
  name: "participated-models",
  props: {
    models: {
      type: Array,
      required: true,
    },
    tag: {
      type: String,
      default: "div",
    },
  },
  computed: {
    ...mapGetters("role", {
      hasRole: "hasRole",
    }),
    hasWriterRole() {
      return this.hasRole(ROLE_WRITER_TOKEN);
    },
    lang() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
