import cameraService from "../api/services/cameraService";

export default () => {
  return {
    namespaced: true,
    state: {
      cameras: {},
      showFilters: false,
    },

    getters: {
      getProfile: (state) => (name) => {
        return state.cameras[name];
      },
      showFilters: (state) => state.showFilters,
    },

    mutations: {
      SET_CAMERA(state, camera) {
        const newCamera = {};
        newCamera[camera.name] = camera;

        state.cameras = {
          ...state.cameras,
          ...newCamera,
        };
      },
      UPDATE_CAMERA(state, camera) {
        let existingCamera = state.cameras[camera.name] || {};
        existingCamera = Object.assign(existingCamera, camera);

        const newCamera = {};
        newCamera[camera.name] = existingCamera;

        state.cameras = {
          ...state.cameras,
          ...newCamera,
        };
      },

      SET_FILTERS_STATUS(state, status) {
        state.showFilters = status;
      },
    },

    actions: {
      async getProfile({ commit }, payload) {
        const response = await cameraService.getProfile(payload.name);
        commit("SET_CAMERA", response);

        return response;
      },

      showFilters({ commit }) {
        commit("SET_FILTERS_STATUS", true);
      },

      hideFilters({ commit }) {
        commit("SET_FILTERS_STATUS", false);
      },
    },
  };
};
