import blogService from "@/api/services/blogService";
import { amplitudeTrack } from "@/utils/amplitude";

export default () => {
  return {
    namespaced: true,
    state: {
      postCampaign: null,
      currentPost: null,
      currentRelatedPosts: [],
    },

    getters: {
      getPostCampaign: (state) => state.postCampaign,
      getCurrentPost: (state) => state.currentPost,
      getCurrentRelatedPosts: (state) => state.currentRelatedPosts,
    },

    mutations: {
      SET_POST_CAMPAIGN(state, campaign) {
        state.postCampaign = campaign;
      },
      SET_CURRENT_POST(state, post) {
        state.currentPost = post;
      },
      SET_CURRENT_RELATED_POSTS(state, relatedPosts) {
        state.currentRelatedPosts = relatedPosts;
      },
    },

    actions: {
      async setPostCampaign({ commit }, campaign) {
        commit("SET_POST_CAMPAIGN", campaign);
      },
      async getPost({ commit }, { lang, alias }) {
        const { post, related_posts } = await blogService.getPost({ lang, alias });

        commit("SET_CURRENT_POST", post);
        commit("SET_CURRENT_RELATED_POSTS", related_posts);
      },
      resetPost({ commit }) {
        commit("SET_CURRENT_POST", null);
        commit("SET_CURRENT_RELATED_POSTS", []);
      },
      track(_, name) {
        amplitudeTrack(name);
      },
    },
  };
};
