import { identify, Identify, init, setDeviceId, setUserId, track } from "@amplitude/marketing-analytics-browser";
import store from "../store/index";
import trackService from "@/api/services/trackService";
import i18n from "../i18n";
import router from "@/router/router";
import { isIframe } from "@/utils/domHelper";

const retryLimit = 2;
let isInitialized = false;
let previousIdentifyObj = null;

export const amplitudeTrack = async (name, props = {}, count = 0) => {
  if (!isInitialized && process.env.VUE_APP_AMPLITUDE_API_KEY) {
    init(process.env.VUE_APP_AMPLITUDE_API_KEY);
    isInitialized = true;
  } else if (!process.env.VUE_APP_AMPLITUDE_API_KEY) {
    isInitialized = true;
    return new Promise((resolve) => {
      console.log("track: " + name, props);
      resolve();
    });
  }

  const identifyObj = {};
  const visit = store.getters["visit/getVisit"] || {};

  props.iframe = isIframe();
  props.site_version = "beta";
  props.URL = window.location.href;
  props["Application language"] = i18n.global.locale;
  props["Is premium"] = "null";
  props.browser = visit.browser;
  props.country = visit.country;
  props.campaign = visit.campaign || "";
  props.device = parseInt(visit.is_mobile) ? "mobile" : "desktop";
  props.app_version = process.env.VUE_APP_VERSION;
  props.app_date = process.env.VUE_APP_DATE;

  const profile = store.getters["user/getProfile"];

  if (profile) {
    const userId = "00000" + profile.id;
    setUserId(userId);
    props.user_id = userId;
    props["Is premium"] = store.getters["user/isPremium"] !== null;

    if (profile.features) {
      Object.keys(profile?.features).forEach((key) => {
        const feature = profile?.features[key];
        const version = feature?.version ?? "true";
        let settings = feature?.settings ?? {};
        settings = Array.isArray(settings) ? settings.reduce((a, v) => ({ ...a, [v]: v }), {}) : settings;

        identifyObj[key] = version;
        identifyObj[`${key}_settings`] = settings;
        props[key] = version;
        props[`${key}_settings`] = settings;
      });
    }

    const subscription = profile.subscription;

    if (profile.subscription) {
      identifyObj.Plan = subscription.type.name;
      identifyObj["Plan token"] = subscription.type.token;
    }

    identifyObj.age = profile.age;
    identifyObj.source = profile.source;
    identifyObj.other_source = profile.other_source;
    identifyObj.registration_campaign = profile.registration_campaign?.id;
    identifyObj["Is premium"] = store.getters["user/isPremium"] !== null;
    identifyObj["Is team member"] = profile.is_team_member;
    identifyObj["Model ID"] = profile.model_id;

    if (JSON.stringify(identifyObj) !== JSON.stringify(previousIdentifyObj) && profile) {
      previousIdentifyObj = identifyObj;
      const newIdentify = new Identify();

      for (const key in identifyObj) {
        newIdentify.set(key, identifyObj[key]);
      }

      identify(newIdentify);
    }
  }

  if (visit.id) {
    const deviceId = "00000" + visit.id;
    setDeviceId(deviceId);
    props.device_id = deviceId;
  }

  const visitFeatures = visit.features;

  if (visitFeatures) {
    Object.keys(visitFeatures).forEach((key) => {
      const feature = visitFeatures[key];

      props[key] = feature?.version ?? "true";
      let settings = feature?.settings ?? {};
      settings = Array.isArray(settings) ? settings.reduce((a, v) => ({ ...a, [v]: v }), {}) : settings;

      props[`${key}_settings`] = settings;
    });
  }

  const response = await track(name, props).promise;
  const code = response?.code || 0;

  if (code >= 400) {
    if (count >= retryLimit) {
      return;
    }

    await amplitudeTrack(name, props, count + 1);
  } else if (code === 0) {
    await trackService.track(name, props);
  }
};

export const amplitudePageViewTrack = async (props) => {
  await amplitudeTrack("Page view", props);
};

export const amplitudeButtonClickTrack = async (buttonName, props = {}) => {
  props["Button name"] = buttonName;
  await amplitudeTrack("Button click", props);
};

export const amplitudeRegistrationButtonClickTrack = async (location, props = {}) => {
  props.location = location;
  await amplitudeTrack("Register button click", props);
};

export const amplitudeTopMenuClickTrack = async (navItemName, props = {}) => {
  props["Navigation item"] = navItemName;
  await amplitudeTrack("Top-menu click", props);
};

export const amplitudeCarouselTrack = async (carouselName) => {
  await amplitudeTrack(`${carouselName}: slide changed`);
};

export const amplitudeVideoTrack = async (eventName, id, props = {}) => {
  props.id = id;
  const video = store.getters["video/getVideo"](id) || null;

  if (video !== null) {
    props["Participated models"] = video.participated_models.map(({ name_en }) => name_en);
    props.Tags = video.tags.map(({ name_en }) => name_en);
    props.trailer = video.tags.filter((tag) => tag.name_en === "Trailer").length > 0;
  }

  props["Pip Status"] = store.getters["player/isPipModeEnabled"] ? "Enabled" : "Disabled";

  if (!props.Location) {
    props.Location = router.currentRoute.value.path;
  }

  await amplitudeTrack(eventName, props);
};
