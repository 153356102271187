import store from "../../store/index";
import { getTld } from "@/utils/urlHelper";
import authMiddleware from "@/router/middleware/authMiddleware";
import popupMiddleware from "@/router/middleware/popupMiddleware";
import freeVideoMiddleware from "@/router/middleware/freeVideoMiddleware";
import unsubscribeMailsMiddleware from "@/router/middleware/unsubscribeMailsMiddleware";
import trackMiddleware from "@/router/middleware/trackMiddleware";

export default [
  ({ to }) => {
    document.title = to.meta.title ? to.meta.title() : "Ersties";
  },
  trackMiddleware,
  ...authMiddleware,
  async ({ to, redirect }) => {
    if (to.matched.some((record) => record.meta.requiresPremium) && !store.getters["user/isPremium"]) {
      await redirect({
        path: store.getters["user/getLandingUrl"],
        query: { redirect: to.fullPath },
      });
    }
  },
  async ({ from, to, redirect }) => {
    if (!store.getters["user/isLoggedIn"]) {
      return true;
    }

    if (to.path === "/") {
      const redirectUrl = store.getters["user/getLandingUrl"];

      await redirect({ path: redirectUrl });

      if (from.path === redirectUrl) {
        await store.dispatch("layout/setGlobalLoading", false);
      }
    }
  },
  async ({ to, redirect }) => {
    if (to.path === "/freetour" && getTld() !== "de") {
      await redirect("/tourpage");
    }
  },
  popupMiddleware,
  freeVideoMiddleware,
  unsubscribeMailsMiddleware,
];
