<template>
  <base-player ref="basePlayer" v-bind="baseProps" @ready="onReady" @send-playback="onSendPlayback">
    <template #default>
      <video-activity-graph ref="activityGraph" :video-id="video.id" :size="graphSize" />
    </template>
  </base-player>
</template>

<script>
import VideoActivityGraph from "@/components/video-activity-graph/index.vue";
import videoService from "@/api/services/videoService";
import { addGoal, setViewPortion } from "@/utils/recommendor";
import BasePlayer from "@/components/player/BasePlayer.vue";
import { getVideoPoster, getVideoTimelinePreview } from "@/utils/mediaHelper";
import { mapGetters } from "vuex";
import browserHelper from "@/utils/browserHelper";
import mobileTimelinePreview from "@/utils/playerHelpers/mobileTimelinePreview";
import "videojs-sprite-thumbnails";
import pipButton from "@/utils/playerHelpers/pipButton";
import { amplitudeVideoTrack } from "@/utils/amplitude";

export default {
  name: "memberarea-player",
  components: { BasePlayer, VideoActivityGraph },
  extends: BasePlayer,
  props: {
    video: {
      type: Object,
      required: true,
    },
    interactive: {
      type: Boolean,
      required: false,
      default: true,
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["onUserActive", "onUserInactive"],
  data() {
    return {
      player: null,
      graphSize: {
        w: 300,
        h: 40,
      },
      timestampsWatched: {},
    };
  },
  computed: {
    ...mapGetters("player", {
      recommendationSourceId: "getRecommendationId",
      isTheaterMode: "isTheaterMode",
      vrType: "getVrType",
    }),
    ...mapGetters("video", {
      isPlayable: "isPlayable",
      activeVideo: "getActiveVideo",
    }),
    baseProps() {
      return {
        id: this.video.id,
        interactive: this.interactive,
        autoplay: this.autoplay && this.interactive,
        trackPlayback: this.interactive,
        poster: this.videoPoster,
        source: this.videoSource,
        sourceType: this.videoSourceType,
        subtitlesEn: this.video.subtitles_en,
        subtitlesDe: this.video.subtitles_de,
        subtitlesFr: this.video.subtitles_fr,
        fullHeight: this.fullHeight,
        ratio: !this.fullHeight ? "16x9" : "",
        hasPreview: !!this.previewSprites.length,
      };
    },
    videoPoster() {
      const posterName = this.video.thumbnails.filter((thumb) => thumb.is_main)[0]?.file_name || "";
      return getVideoPoster(posterName);
    },
    videoSource() {
      if (!this.isPlayable(this.video)) {
        return null;
      }

      if (!this.video.is_vr) {
        return this.video.hls_url;
      }

      switch (this.vrType) {
        case "google_cardbox":
          return this.video.vr_google_url;
        case "desktop_device":
        default:
          return this.video.vr_oculus_url;
      }
    },
    videoSourceType() {
      if (!this.video.is_vr) {
        return "application/x-mpegURL";
      }

      return "video/mp4";
    },
    vrProjection() {
      if (!this.video.is_vr) {
        return null;
      }

      let projection = this.video.panorama_degrees.toString();

      if (this.video.is_tridimensional) {
        projection += "_LR";
      }

      return projection;
    },

    previewSprites() {
      if (!this.video.duration) {
        return [];
      }

      const count = Math.ceil(this.video.duration / 5);
      const sprites = [];

      for (let i = 1; i <= count; i++) {
        sprites.push(getVideoTimelinePreview(`${this.video.id}/img${i}.png`));
      }

      return sprites;
    },
  },
  watch: {
    activeVideo(newVal, oldVal) {
      if (oldVal === this.video.id && this.player) {
        this.sendPortionWatched();
      }
    },
    autoplay(value) {
      if (value) {
        if (!this.videoSource) {
          this.$store.dispatch("user/showSubscriptionPaywall");
        } else {
          if (this.player && this.player.paused()) {
            setTimeout(() => {
              this.player.play().catch(() => {});
            }, 100);
          }
        }
      }
    },
  },
  methods: {
    onReady(player) {
      this.player = player;

      pipButton(this.player);
      this.setActivityGraph();
      this.setPreviewSprites(this.player);

      if (this.vrProjection) {
        this.setVR(this.player);
      }

      this.player.on("timeupdate", () => {
        const curTime = +this.player.currentTime().toFixed(0);

        this.timestampsWatched[curTime] = 1;
      });
      this.player.on("useractive", () => {
        this.$emit("onUserActive");
      });
      this.player.on("userinactive", () => {
        this.$emit("onUserInactive");
      });

      window.addEventListener("beforeunload", this.sendPortionWatched);
    },
    setActivityGraph() {
      const progressBar = this.$refs.basePlayer.$refs.videoWrapper.querySelector(".vjs-progress-control");
      progressBar.append(this.$refs.activityGraph.$el);
    },
    setPreviewSprites(player) {
      if (!this.previewSprites.length) {
        return;
      }

      if (this.interactive) {
        this.previewSprites.forEach((url) => {
          const tempImage = new Image();
          tempImage.src = url;
          tempImage.decode().catch(() => {});
        });
      }

      if (browserHelper.isMobileDevice) {
        mobileTimelinePreview(player, this.previewSprites);
      } else {
        player.spriteThumbnails({
          urlArray: this.previewSprites,
          columns: 1,
          rows: 1,
          width: 160,
          height: 90,
          interval: 5,
          duration: 5,
          downlink: 0,
        });
      }
    },
    setVR(player) {
      player.vr({
        projection: this.vrProjection,
      });
    },
    onSendPlayback(params) {
      if (!params) {
        return;
      }

      videoService
        .playback(params)
        .catch(() => {})
        .finally(() => {
          const basePlayer = this.$refs.basePlayer;

          if (basePlayer) {
            basePlayer.$data.log.playbackSent = true;
          }
        });
    },
    sendPortionWatched() {
      if (!this.video.duration || !this.video.recommendation_item_id) {
        return;
      }

      const durationWatched = Object.keys(this.timestampsWatched).length;

      if (!durationWatched) {
        return;
      }

      const portion = Math.min(1, Object.keys(this.timestampsWatched).length / this.video.duration);
      this.timestampsWatched = {};

      if (portion >= 0.05) {
        setViewPortion(this.video.recommendation_item_id, portion, this.recommendationSourceId);
      }

      if (portion >= 0.7) {
        addGoal(this.video.recommendation_item_id, this.recommendationSourceId);
      }

      this.$store.dispatch("video/setLastPortion", portion);
    },
    setActivityGraphSize() {
      if (!this.$refs.basePlayer.$refs.videoWrapper) {
        return;
      }

      this.graphSize.w = this.$refs.basePlayer.$refs.videoWrapper.offsetWidth;
      this.graphSize.h = this.graphSize.w > 576 ? 40 : 25;
    },
    setFullHeight() {
      const root = this.$refs.basePlayer.$el;

      if (root) {
        root.style.setProperty("--app-height", `${window.innerHeight}px`);
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.setActivityGraphSize);
    this.setActivityGraphSize();

    if (this.fullHeight || browserHelper.isMobileDevice) {
      this.setFullHeight();
      window.addEventListener("resize", this.setFullHeight);

      if (screen?.orientation) {
        screen.orientation.addEventListener("change", this.setFullHeight);
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.sendPortionWatched);
    window.removeEventListener("resize", this.setActivityGraphSize);
    window.removeEventListener("resize", this.setFullHeight);

    if (screen?.orientation) {
      screen.orientation.removeEventListener("change", this.setFullHeight);
    }

    const isVideoChanged = this.activeVideo && this.video.id !== this.activeVideo;
    const isClosed = !this.activeVideo && !this.isTheaterMode;

    if (!isVideoChanged && !isClosed) {
      return;
    }

    this.sendPortionWatched();
    this.$store.dispatch("video/setLastPortion", null);

    if (isClosed) {
      amplitudeVideoTrack("Video Player close", {
        Captions: this.currentTextTrackIndex !== null ? this.subtitles[this.currentTextTrackIndex].lang : null,
      });
      this.$store.dispatch("video/setActiveVideo", {
        videoId: null,
      });
    }
  },
};
</script>
