<template>
  <div>
    <preloader v-if="isLoading" />
    <template v-else>
      <component :is="layout">
        <router-view />
      </component>

      <transition name="fade">
        <law-restriction-popup v-if="canShowLawRestrictionPopup" />
      </transition>

      <template v-if="layoutName !== LAYOUT_BLANK">
        <transition name="fade">
          <cookie-consent v-if="canShowCookieConsent" />
          <cookies-popup v-else-if="canShowCookiesPopup" />
        </transition>
      </template>
    </template>

    <transition name="fade">
      <theater-player v-if="isTheaterMode" />
    </transition>
  </div>
</template>

<script>
import Preloader from "./components/preloader";

import { mapGetters } from "vuex";
import {
  LAYOUT_BLANK,
  LAYOUT_FREETOUR,
  LAYOUT_LADIES,
  LAYOUT_LADIES_V2,
  LAYOUT_V2,
  LAYOUT_V2_BLOG,
  ROUTE_SCROLL_DELAY,
} from "@/utils/variables";
import Bugsnag from "@bugsnag/js";
import { ApiError } from "@/api/apiError";
import { getApprovedCookies, loadLanguage, removeAccessToken } from "@/utils/storage";
import browserHelper from "@/utils/browserHelper";
import googleSignup from "@/utils/googleSignup";
import { initHotjar } from "./utils/hotjar";
import getFlash from "@/utils/getFlash";
import { defineAsyncComponent } from "vue";
import { changeLanguage } from "@/i18n";
import LawRestrictionPopup from "@/components/popups/law-restriction-popup/index.vue";
import { getTld } from "@/utils/urlHelper";
import TheaterPlayer from "@/components/theater-player/index.vue";

const CookieConsent = defineAsyncComponent(() => import("@/components/v2/cookie-consent/index.vue"));
const CookiesPopup = defineAsyncComponent(() => import("@/components/cookies-popup"));
const layout_main = defineAsyncComponent(() => import("./components/layout-main"));
const layout_freetour = defineAsyncComponent(() => import("@/components/layout-freetour"));
const layout_blank = defineAsyncComponent(() => import("@/components/layout-blank"));
const layout_ladies = defineAsyncComponent(() => import("@/components/layout-ladies"));
const layout_ladies_v2 = defineAsyncComponent(() => import("@/components/v2/layout-ladies-v2"));
const layout_v2 = defineAsyncComponent(() => import("@/components/v2/layout"));
const layout_v2_blog = defineAsyncComponent(() => import("@/components/v2/layout-blog"));

export default {
  name: "app",
  components: { TheaterPlayer, LawRestrictionPopup, CookieConsent, CookiesPopup, Preloader },
  data() {
    return {
      canShowCookieConsent: false,
      LAYOUT_BLANK,
    };
  },
  computed: {
    ...mapGetters("user", ["isLoggedIn", "getProfile"]),
    ...mapGetters("popup", ["canShowCookiesPopup", "canShowLawRestrictionPopup"]),
    ...mapGetters("layout", {
      isLoading: "getGlobalLoading",
      isBackNavigation: "isBackNavigation",
      isContentLoaded: "getContentLoaded",
      pagePosition: "getPagePosition",
    }),
    ...mapGetters("visit", { visit: "getVisit", isEUCountry: "isEUCountry" }),
    ...mapGetters("player", {
      isTheaterMode: "isTheaterMode",
    }),
    ...mapGetters("feature", ["hasFrench"]),
    layoutName() {
      return typeof this.$route.meta.layout === "function" ? this.$route.meta.layout() : this.$route.meta.layout;
    },
    layout() {
      switch (this.layoutName) {
        case LAYOUT_FREETOUR:
          return layout_freetour;
        case LAYOUT_BLANK:
          return layout_blank;
        case LAYOUT_LADIES:
          return layout_ladies;
        case LAYOUT_LADIES_V2:
          return layout_ladies_v2;
        case LAYOUT_V2:
          return layout_v2;
        case LAYOUT_V2_BLOG:
          return layout_v2_blog;
        default:
          return layout_main;
      }
    },
    bodyLayoutClass() {
      switch (this.layout) {
        case layout_v2:
        case layout_v2_blog:
        case layout_ladies_v2:
          return "v2_freearea";
        case layout_ladies:
          return "layout-ladies";
        default:
          return "layout-memberarea";
      }
    },
    scrollOnBackPossible() {
      return this.isBackNavigation && this.isContentLoaded;
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("layout/setIsBackNavigation", window.history.state.position + 2 <= window.history.length);
      this.checkCookieConsent();
      googleSignup.togglePrompt();
    },
    $i18n(newVal, oldVal) {
      if (newVal.locale !== oldVal.locale) {
        if (this.$route.meta.title) {
          document.title = this.$route.meta.title();
        }
      }
    },
    isLoggedIn(value) {
      if (value === false) {
        if (this.isTheaterMode) {
          this.$store.dispatch("player/setTheaterMode", { videoId: null });
        }

        googleSignup.init();
      }

      this.$store.dispatch("company/getCompany", false);
    },

    visit(value) {
      if (!value) {
        return;
      }

      this.$store.dispatch("company/getCompany", false);

      this.checkCookieConsent();

      if (value.new || getFlash("first_visit")) {
        if (this.hasFrench) {
          initHotjar();
        }

        const lang = this.hasFrench && value.country === "FR" ? "fr" : value.is_international ? "en" : "de";

        if (this.$root.$i18n.locale !== lang) {
          changeLanguage(lang);
        }
      }
    },
    bodyLayoutClass(newVal, oldVal) {
      if (newVal) {
        document.body.classList.add(newVal);
      }

      if (oldVal) {
        document.body.classList.remove(oldVal);
      }
    },

    scrollOnBackPossible(value) {
      if (value && this.pagePosition !== null) {
        this.$nextTick(() => {
          setTimeout(() => {
            window.scroll({
              top: this.pagePosition.top,
              behavior: "instant",
            });
          }, ROUTE_SCROLL_DELAY + 10);
        });
      }
    },
  },
  methods: {
    checkCookieConsent() {
      this.canShowCookieConsent = getTld() !== "io" && this.isEUCountry && !getApprovedCookies();
    },
  },
  created() {
    this.$i18n.locale = loadLanguage() || document.documentElement.getAttribute("lang") || "en";
    this.$store.dispatch("layout/setGlobalLoading", true);
    document.body.classList.add(this.bodyLayoutClass);
    window.addEventListener("unhandledrejection", (e) => {
      e.preventDefault();
      const status = e.reason?.status?.status;

      if (status !== 401) {
        if (e.reason instanceof ApiError && Bugsnag.isStarted()) {
          Bugsnag.notify(e.reason);
        } else {
          console.debug(e);
        }
      } else {
        if (this.getProfile) {
          removeAccessToken();
          this.$store.dispatch("user/logout", {
            path: "/#log_in",
            query: { redirect: this.$route.fullPath },
          });
        }
      }
    });
    googleSignup.init();
    browserHelper.init();
  },
};
</script>
